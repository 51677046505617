  
import React, { Component } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Row from 'react-bootstrap/Row';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faUser as solidUser} from '@fortawesome/free-solid-svg-icons'
import {faUser as regularUser} from '@fortawesome/free-regular-svg-icons'

class CoachDetail extends Component {
  render() {
    return (
      <div class="coach">
        <div>
          <Jumbotron>
            <Container>
              <h2>Sergey Deshevvy</h2>
            </Container>
          </Jumbotron>
          <Row>
            <Col sm={6}>
              <div >
                <FontAwesomeIcon icon={regularUser} size="2x"/> <span class="availability">Available</span>
              </div>
            </Col>
            <Col sm={6}>
              <div>
                <FontAwesomeIcon icon={solidUser} size="2x" color="blue"/> <span class="availability">Unavailable</span>
              </div>  
            </Col> 
          </Row>
        </div>
        <div class="session-date">
          <h3>Monday</h3>
          <div class="session">
            <Container fluid="true" bsPrefix="user-container">
              <Row>
                <Col xs={12}>
                  <div class="timeslot">
                    4:15pm - 5:15pm (10U - 12U)  
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={3}>
                  <FontAwesomeIcon icon={solidUser} size="3x" color="blue"/>
                </Col> 
                <Col xs={3}>
                  <FontAwesomeIcon icon={solidUser} size="3x" color="blue"/>
                </Col>
                <Col xs={3}>
                  <FontAwesomeIcon icon={regularUser} size="3x"/>
                </Col>
                <Col xs={3}>
                  <FontAwesomeIcon icon={regularUser} size="3x"/>
                </Col> 
              </Row>
            </Container> 
          </div>
          <div class="session">
            <Container fluid="true" bsPrefix="user-container">
              <Row>
                <Col xs={12}>
                  <div class="timeslot">
                  5:15pm - 6:15pm (14U)  
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={3}>
                  <FontAwesomeIcon icon={solidUser} size="3x" color="blue"/>
                </Col> 
                <Col xs={3}>
                  <FontAwesomeIcon icon={regularUser} size="3x"/>
                </Col>
                <Col xs={3}>
                  <FontAwesomeIcon icon={regularUser} size="3x"/>
                </Col>
                <Col xs={3}>
                  <FontAwesomeIcon icon={regularUser} size="3x"/>
                </Col> 
              </Row>
            </Container> 
          </div>
        </div>
      </div>  
    );

    
  }
}

export default CoachDetail;