import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { withRouter } from 'react-router-dom'

class CoachSummary extends Component {

  handleCoachClick = () => {
    console.log("clicked coach");
    this.props.history.push("/coach");    
  }

  render() {
    return (
      <div class="coach-week" onClick={this.handleCoachClick}>
        <h3>Sergey Deshevvy</h3>
          <ButtonGroup>
          <Button variant="primary" disabled="true">Mon</Button>
          <Button variant="light" disabled="true">Tue</Button>
          <Button variant="primary" disabled="true">Wed</Button>
          <Button variant="primary" disabled="true">Thu</Button>
          <Button variant="primary" disabled="true">Fri</Button>
        </ButtonGroup>
      </div>
    );
  }
}

export default withRouter(CoachSummary);