import React from 'react'
import { Switch, Route } from 'react-router-dom'

import LessonWeek from './LessonWeek';
import CoachDetail from './CoachDetail'

const Main = () => (
  <main>
    <Switch>
      <Route exact path='/' component={LessonWeek}/>
      <Route path='/coach' component={CoachDetail}/>
    </Switch>  
  </main>
)

export default Main;