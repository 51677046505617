import React from 'react'
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';


const Header = () => (
  <header>
    <Container fluid="true">
      <Image src="warriors-logo.png" fluid />
      <h1>Private Lessons</h1>
    </Container>
  </header>
)

export default Header;