import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

import CoachSummary from './CoachSummary';

class LessonWeek extends Component {
  render() {
    return (
      <Container fluid="true" >
        <Row>
          <Col lg={4}>
            <CoachSummary/>
          </Col>
          <Col lg={4}>
            <CoachSummary/>
          </Col>
          <Col lg={4}>
            <CoachSummary/>
          </Col>
        </Row>
        <Row>
          <Col lg={4}>
            <div class="coach-week">
              <h3>Anthony Pike</h3>
              <ButtonGroup>
                <Button variant="primary" disabled="true">Mon</Button>
                <Button variant="light" disabled="true">Tue</Button>
                <Button variant="primary" disabled="true">Wed</Button>
                <Button variant="primary" disabled="true">Thu</Button>
                <Button variant="primary" disabled="true">Fri</Button>
              </ButtonGroup>
            </div>
          </Col>
          <Col lg={4}>
            <div class="coach-week">
              <h3>Kenny Carrol</h3>
              <ButtonGroup>
                <Button variant="primary" disabled="true">Mon</Button>
                <Button variant="light" disabled="true">Tue</Button>
                <Button variant="primary" disabled="true">Wed</Button>
                <Button variant="primary" disabled="true">Thu</Button>
                <Button variant="primary" disabled="true">Fri</Button>
              </ButtonGroup>
            </div>
          </Col>
          <Col lg={4}>
            <div class="coach-week">
              <h3>Joey Catalano</h3>
              <ButtonGroup>
                <Button variant="primary" disabled="true">Mon</Button>
                <Button variant="light" disabled="true">Tue</Button>
                <Button variant="primary" disabled="true">Wed</Button>
                <Button variant="primary" disabled="true">Thu</Button>
                <Button variant="primary" disabled="true">Fri</Button>
              </ButtonGroup>
            </div>
          </Col>
        </Row>
      
    </Container>
    );
  }
}

export default LessonWeek;