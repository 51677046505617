import React from 'react';


// import Coach from './Coach';
import Header from './Header';
import Main from './Main';

import './App.css';

function App() {
  return (
    <div className="App">
      <Header/>
      <Main/>
    </div>
  );
}

export default App;
